module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"minimal-ui"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-167441500-1"},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"mister-portfolio","defaultLang":"de-de","accessToken":"MC5Yc1BKdmhJQUFDTUFNV2ds.77-9WQPvv70bUu-_vXAmIO-_ve-_vRZ2EO-_vU1KF--_ve-_vXnvv73vv73vv71y77-9DkwnA--_vQ","path":"/preview","previews":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
